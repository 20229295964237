@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

html,
body,
#root {
  font-family: 'Roboto', sans-serif;
  background: #E4E4E4;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

#root {
  height: auto;
  width: 100%;
}

#modal-root,
#loader-root {
  height: auto;
  width: 100%;
}

.no-scrolable {
  overflow: hidden;
}

.scrolable {
  overflow-y: auto;
}

button,
button:hover,
button:focus,
button:active,
input,
a {
  outline: none !important;
}

textarea {
  resize: none !important;
}

img {
  user-select: none !important;
  max-width: 100%;
}

.flex-col {
  display: flex !important;
  flex-direction: column !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.page-title-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .page-title {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #193B68;
    margin-bottom: 0;

    span {
      margin: 0 10px;
    }
  }

  .add-btn-wrap {
    min-width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tools-wrap {
  display: flex;
}
