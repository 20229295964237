.modal {
  background: rgba(236, 236, 236, 0.9);
}

.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 299;
  background: rgba(236, 236, 236, 0.9);

  .spinner-border {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 42%;
    left: 48%;
    z-index: 300;
  }
}
