.navbar-brand {
  background: url('../../../assets/img/logo.png') no-repeat;
  background-position: center;
  background-size: cover;
  height: 40px;
  width: 40px;
}

.main-navbar-collapse {
  position: relative;

  .navbar-nav-right {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
  }

  .language-control-wrap {
    margin-right: 10px;
  }

  button {
    background: none;
    border: 0;
    outline: 0;
    color: #fff;
    transition: color 200ms;

    &:hover {
      color: rgba(255,255,255,0.55);
    }
  }
}
