.search-field-wrap {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 440px;
  min-width: 300px;
  margin-right: 10px;

  .btn {
    border-radius: 0;
    border: 0;
    border-left: 1px solid rgba(25, 59, 104, 0.2);
    outline: 0;
    padding: 0;
    position: absolute;
    background: transparent;
    width: 36px;
    height: 42px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 200ms;

    &.search {
      right: 36px;
    }

    &.clear {
      right: 2px;
    }

    &:hover {
      opacity: 0.4;
    }
  }

  input.form-control {
    outline: 0;
    outline-offset: 0;
    padding-right: 72px;
  }

  input:active,
  input:focus {
    border: 1px solid #4F00FD;
  }

  input::placeholder {
    color: rgba(25, 59, 104, 0.2);
  }

  .form-floating {
    width: 100%;
  }
}
