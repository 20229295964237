.language-control-wrap {
  padding: 0 5px;
  position: relative;
  text-align: end;

  .lang-btn {
    background: none;
    border: 0;
    outline: 0;

    &.active {
      text-decoration: underline;
    }
  }
}
